



















































import { Component, Vue } from 'vue-property-decorator';
import { internet } from '@/utils/Internet';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { IDataTable } from '@/model/main/IDataTable';
import Util from '@/utils/Util';
@Component({
		name: 'MainCalidadTAS',
		components: {

		},
	})
    export default class MainCalidadTAS extends Vue {
        public elementPerPage = this.$t("DataTable.elementPerPage");
        public countElementPage = this.$t("DataTable.countElementPage");
        public search = '';
        //relleno tabla para mejores resultados TAS
        public header: Array<IHeaderTable<IDataTable>> = [
			{ text: 'Agente', value: 'agente', align: 'start'},
			{ text: 'Nombre Audio', value: 'nombreAudio'},
			{ text: 'Campaña', value: 'campania'},
			{ text: 'Tipo llamada', value: 'tipoLlamada'},
            { text: 'TAS', value: 'tas'},
            { text: 'Detalle', value: 'detalle'},
		]
        public rows: Array<IDataTable> = [];

        mounted(){
           // this.obtenerLlamadasResultadosTAS()      
        }
        
        public obtenerLlamadasResultadosTAS(){
            const request_1 = internet.newRequest().get('/monitoreos/getResultadoTAS?orden=-1');
            const request_2 = internet.newRequest().get('/monitoreos/getResultadoTAS?orden=1');
			Util.waitForPromises([request_1, request_2])
            .then((result) => {
                let response1 = result[0]
                let response2 = result[1]
                const list: Array<IDataTable> = []
            for (let item of response1.data){
                console.log("aqui", item)
                let resultadoEvaTiempo = {
                    id_tabla: item.nombreAudio + item.agente,
                    agente: item.agente,
                    nombreAudio: item.nombreAudio,
                    campania: item.campania,
					tipoLlamada: item.tipoLlamada,
                    tas: item.tas
                }
                list.push(resultadoEvaTiempo)
            }
            console.log(list)
            this.rows = list 

            const list2: Array<IDataTable> = []
            for (let item of response2.data){
                console.log("aqui2", item)
                let resultadoEvaTiempoMax = {
                    id_tablaPeorTAS: item.nombreAudio + item.agente + 'peorTAS',
                    agentePeorTAS: item.agente,
                    nombreAudioPeorTAS: item.nombreAudio,
                    campaniaPeorTAS: item.campania,
					tipoLlamadaPeorTAS: item.tipoLlamada,
                    tasPeorTAS: item.tas
                }
                list2.push(resultadoEvaTiempoMax)
            }
            console.log(list2)
            // this.rowsPeorTAS = list2 


            })
        }


        public getTextCountElementPerPage(
            to: number,
			end: number
            ): string {
                let text = Util.replaceTextWith(
                    this.countElementPage as string,
                    '$1',
                    to.toString()
                    );
                    text = Util.replaceTextWith(
                        text,
                        '$2',
                        end.toString()
                        );
                        return text;
                    }
    }
